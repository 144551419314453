import { AccessKey } from '@/plugins/authorization';
import { useUserStore } from '@/store/user';

export const hasAccess = (key: keyof typeof AccessKey | undefined): string => {
  const accessStore = useUserStore();
  // Check access and return appropriate string value
  return key ? (accessStore.getAccess[AccessKey[key]] === false ? 'no-access' : '') : '';
};

export { AccessKey };
