import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

import { ROUTES } from './helpers/routes';

const InternalRoutes: Array<RouteRecordRaw> = [
  // Internal Pages and Design System
  {
    path: ROUTES.INTERNAL_LIST.path,
    name: ROUTES.INTERNAL_LIST.name,
    component: () => import('../pages/internal/InternalIndex.vue'),
    props: true,
    meta: {
      title: ROUTES.INTERNAL_LIST.title,
      access: {
        url: 'internal',
        method: 'GET',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.INTERNAL_JSON_FORMS.path,
    name: ROUTES.INTERNAL_JSON_FORMS.name,
    component: () => import('../pages/internal/JsonExampleVue.vue'),
    props: true,
    meta: {
      title: ROUTES.INTERNAL_JSON_FORMS.title,
    },
  },
  {
    path: ROUTES.INTERNAL_JSON_FORMS_PREVIEW.path,
    name: ROUTES.INTERNAL_JSON_FORMS_PREVIEW.name,
    component: () => import('../pages/internal/FormPreview.vue'),
    meta: {
      title: ROUTES.INTERNAL_JSON_FORMS_PREVIEW.title,
    },
  },
  {
    path: ROUTES.INTERNAL_OPA_STATUS.path,
    name: ROUTES.INTERNAL_OPA_STATUS.name,
    component: () => import('../pages/internal/OPAOverview.vue'),
    props: true,
    meta: {
      title: ROUTES.INTERNAL_OPA_STATUS.title,
    },
  },
];

export default InternalRoutes;
