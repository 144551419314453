import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';
import { useCarrierProfileStore } from '@/store/carrier-profile';

import { ROUTES } from './helpers/routes';

const CarrierConfiguration: Array<RouteRecordRaw> = [
  // Carrier Profile configuration
  {
    path: ROUTES.CARRIER_PROFILE_CREATE.path,
    name: ROUTES.CARRIER_PROFILE_CREATE.name,
    component: () => import('../pages/configuration/CreateCarrierProfile.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_CREATE.title,
      access: {
        url: 'locations/:location/carrierProfiles',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },

  {
    path: ROUTES.CARRIER_LOCATION_LIST.path,
    name: ROUTES.CARRIER_LOCATION_LIST.name,
    component: () => import('../pages/configuration/LocationSelection.vue'),
    meta: {
      title: ROUTES.CARRIER_LOCATION_LIST.title,
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_LIST.path,
    name: ROUTES.CARRIER_PROFILE_LIST.name,
    props: true,
    component: () => import('../pages/configuration/CarrierProfiles.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_LIST.title,
    },
    beforeEnter: (to, from, next) => {
      const store = useCarrierProfileStore();
      store.$reset();
      next();
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_EDIT.path,
    name: ROUTES.CARRIER_PROFILE_EDIT.name,
    props: true,
    component: () => import('../pages/configuration/CreateCarrierProfile.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_EDIT.title,
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_ONBOARDING.path,
    name: ROUTES.CARRIER_PROFILE_ONBOARDING.name,
    props: true,
    component: () => import('../pages/configuration/CarrierOnboarding.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_ONBOARDING.title,
    },
  },
  {
    path: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.path,
    name: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.name,
    props: true,
    component: () => import('../pages/configuration/CarrierIntegration.vue'),
    meta: {
      title: ROUTES.CARRIER_PROFILE_INTEGRATION_EDIT.title,
    },
  },
];

export default CarrierConfiguration;
