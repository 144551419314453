<script lang="ts" setup>
import { Toaster as Sonner, type ToasterProps } from 'vue-sonner';

const props = defineProps<ToasterProps>();
</script>

<template>
  <Sonner
    class="toaster group"
    v-bind="props"
    :toast-options="{
      classes: {
        toast:
          'group toast group-[.toaster]:bg-default group-[.toaster]:text-default group-[.toaster]:border-default group-[.toaster]:shadow-lg',
        description: 'group-[.toast]:text-muted',
        actionButton: 'group-[.toast]:bg-default group-[.toast]:text-accent',
        cancelButton: 'group-[.toast]:bg-default group-[.toast]:text-muted',
      },
    }"
  />
</template>
