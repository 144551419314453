<script lang="ts">
import { computed, type Ref, ref } from 'vue';

export default function useCurrencyFormatter(
  value: Ref<number | null>,
  currency: Ref<string> = ref('USD'),
  locale: Ref<string> = ref('en-US')
) {
  const formattedValue = computed(() => {
    if (value.value === null) return '';
    return new Intl.NumberFormat(locale.value, {
      style: 'currency',
      currency: currency.value,
    }).format(value.value);
  });

  return {
    formattedValue,
  };
}
</script>
