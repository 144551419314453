import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const Rates: Array<RouteRecordRaw> = [
  {
    path: ROUTES.RATE_CONTRACT_LIST.path,
    name: ROUTES.RATE_CONTRACT_LIST.name,
    component: () => import('../pages/rates/RatesContracts.vue'),
    meta: {
      title: ROUTES.RATE_CONTRACT_LIST.title,
    },
  },
  {
    path: ROUTES.RATE_LOAD.path,
    name: ROUTES.RATE_LOAD.name,
    component: () => import('../pages/rates/RatesConfiguration.vue'),
    props: true,
    meta: {
      title: ROUTES.RATE_LOAD.title,
    },
  },
  {
    path: ROUTES.RATE_SERVICE_LEVEL_CREATE.path,
    name: ROUTES.RATE_SERVICE_LEVEL_CREATE.name,
    component: () => import('../views/rates/serviceLevels/EditServiceLevelForm.vue'),
    props: {
      new: true,
    },
    meta: {
      title: ROUTES.RATE_SERVICE_LEVEL_CREATE.title,
    },
  },
  {
    path: ROUTES.RATE_SERVICE_LEVEL_EDIT.path,
    name: ROUTES.RATE_SERVICE_LEVEL_EDIT.name,
    component: () => import('../views/rates/serviceLevels/EditServiceLevelForm.vue'),
    props: {
      new: false,
    },
    meta: {
      title: ROUTES.RATE_SERVICE_LEVEL_EDIT.title,
    },
  },
];

export default Rates;
