import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

import { ROUTES } from './helpers/routes';

const Shipments: Array<RouteRecordRaw> = [
  {
    path: ROUTES.SHIPMENT_LIST.path,
    name: ROUTES.SHIPMENT_LIST.name,
    component: () => import('../pages/shipment/overview/ShipmentOverview.vue'),
    meta: {
      title: ROUTES.SHIPMENT_LIST.title,
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_LIST_TEST.path,
    name: ROUTES.SHIPMENT_LIST_TEST.name,
    component: () => import('../pages/shipment/overview/ShipmentOverview.vue'),
    meta: {
      title: ROUTES.SHIPMENT_LIST_TEST.title,
      testMode: true,
      access: {
        url: 'shipments',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_DETAIL.path,
    name: ROUTES.SHIPMENT_DETAIL.name,
    component: () => import('../pages/shipment/ShipmentPage.vue'),
    props: true,
    meta: {
      title: ROUTES.SHIPMENT_DETAIL.title,
      access: {
        url: ROUTES.SHIPMENT_DETAIL.path,
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_DETAIL_TEST.path,
    name: ROUTES.SHIPMENT_DETAIL_TEST.name,
    component: () => import('../pages/shipment/ShipmentPage.vue'),
    props: true,
    meta: {
      title: ROUTES.SHIPMENT_DETAIL_TEST.title,
      testMode: true,
      access: {
        url: ROUTES.SHIPMENT_DETAIL.path,
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_EDIT.path,
    name: ROUTES.SHIPMENT_EDIT.name,
    component: () => import('../pages/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.SHIPMENT_EDIT.title,
      access: {
        url: ROUTES.SHIPMENT_EDIT.path,
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_EDIT_TEST.path,
    name: ROUTES.SHIPMENT_EDIT_TEST.name,
    component: () => import('../pages/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: ROUTES.SHIPMENT_EDIT_TEST.title,
      testMode: true,
      access: {
        url: ROUTES.SHIPMENT_EDIT.path,
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_CREATE.path,
    name: ROUTES.SHIPMENT_CREATE.name,
    component: () => import('../pages/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.SHIPMENT_CREATE.title,
      access: {
        url: 'shipments',
        method: 'POST',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.SHIPMENT_CREATE_TEST.path,
    name: ROUTES.SHIPMENT_CREATE_TEST.name,
    component: () => import('../pages/shipment/create/ShipmentCreate.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: ROUTES.SHIPMENT_CREATE_TEST.title,
      testMode: true,
      access: {
        url: 'shipments',
        method: 'POST',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },

  // Shareable public pages: Order Request (for carriers) and Shipment Tracking (for receivers/end-customers)
  {
    path: ROUTES.ORDER_REQUEST_DETAIL.path,
    name: ROUTES.ORDER_REQUEST_DETAIL.name,
    component: () => import('../pages/shipment/OrderRequestPage.vue'),
    meta: {
      title: ROUTES.ORDER_REQUEST_DETAIL.title,
      allowAnonymous: true,
    },
  },
  {
    path: ROUTES.SHIPMENT_TRACKING_DETAIL.path,
    name: ROUTES.SHIPMENT_TRACKING_DETAIL.name,
    component: () => import('../pages/shipment/ShipmentTrackingPage.vue'),
    meta: {
      title: ROUTES.SHIPMENT_TRACKING_DETAIL.title,
      allowAnonymous: true,
    },
  },
];

export default Shipments;
