import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

import { ROUTES } from './helpers/routes';

const Tokens: Array<RouteRecordRaw> = [
  {
    path: ROUTES.TOKEN_LIST.path,
    name: ROUTES.TOKEN_LIST.name,
    component: () => import('../pages/tokens/TokensIndex.vue'),
    props: true,
    meta: {
      title: ROUTES.TOKEN_LIST.title,
      access: {
        url: 'tokens',
        method: 'GET',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.TOKEN_CREATE.path,
    name: ROUTES.TOKEN_CREATE.name,
    component: () => import('../pages/tokens/TokenCreate.vue'),
    props: true,
    meta: {
      title: ROUTES.TOKEN_CREATE.title,
      access: {
        url: 'tokens',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: ROUTES.TOKEN_DETAIL.path,
    name: ROUTES.TOKEN_DETAIL.name,
    component: () => import('../pages/tokens/TokenDetail.vue'),
    props: true,
    meta: {
      title: ROUTES.TOKEN_DETAIL.title,
      access: {
        url: 'tokens/:hash',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
];

export default Tokens;
