<template>
  <div class="flex min-h-screen">
    <NavSideBar v-if="isAuthenticated" />
    <div class="min-w-0 flex-1 grow pb-6">
      <NavTop v-if="isAuthenticated" />
      <div id="top-alerts">
        <BaseAlert v-if="showAlertInfo" variant="info" icon="PhRocketLaunch">
          <IconComponent
            @click="showAlertInfo = false"
            name="PhX"
            class="float-right cursor-pointer"
          />
          <strong>Heads Up!</strong>
          We're updating our interface in the coming weeks. Have feedback or run into issues?
          <a
            href="https://github.com/ShipitSmarter/support/issues"
            target="_blank"
            class="underline"
          >
            Contact us here
          </a>
        </BaseAlert>
        <BaseAlert v-if="showAlertLocation && isLocationActive" variant="warning" icon="PhWarning">
          <IconComponent
            @click="showAlertLocation = false"
            name="PhX"
            class="float-right cursor-pointer"
          />
          <strong>No location found!</strong>
          To use Viya, you need to
          <RouterLink :to="{ name: ROUTES.LOCATION_LIST.name }" class="underline">
            add a location
          </RouterLink>
          first.
        </BaseAlert>
        <BaseAlert v-if="route.meta.testMode" variant="warning">
          <strong>Welcome to test mode!</strong>
          Test shipments and consignments are not shipped!
        </BaseAlert>
        <BaseAlert v-if="route.meta.betaMode" variant="warning">
          <div>
            <strong>Heads Up!</strong>
            We're still actively developing this page! Have feedback or run into issues?
            <a
              href="https://github.com/ShipitSmarter/support/issues"
              target="_blank"
              class="underline"
            >
              Contact us here
            </a>
          </div>
        </BaseAlert>
      </div>
      <div
        class="xl:max-w-screen-xxl mx-auto px-5 pt-3 lg:max-w-screen-xl"
        :class="routerViewClass"
      >
        <RouterView />
      </div>
      <Toaster />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';
import { useStorage } from '@vueuse/core';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import BaseAlert from '@/components/alert/BaseAlert.vue';
import NavTop from '@/components/navTop/NavTop.vue';
import { Toaster } from '@/components/ui/sonner';
import { useContextStore } from '@/store';
import { useUserStore } from '@/store/user';
import NavSideBar from '@/views/navigation/NavSideBar.vue';

import { ROUTES } from './router/helpers/routes';

const router = useRouter();
const contextStore = useContextStore();
const userStore = useUserStore();

const route = useRoute();

const showAlertInfo = useStorage('ShowAlert1', true, sessionStorage);
const showAlertLocation = useStorage('ShowAlertLocation', true, sessionStorage);
const isLocationActive = computed(
  () => contextStore.locationCount === 0 && contextStore.locationsLoaded
);

// Use a computed property to get the class from the route meta
const routerViewClass = computed(() => route.meta.bodyClass || '');

const isAuthenticated = computed(() => userStore.isAuthenticated);

onMounted(async () => {
  await router.isReady();
  if (isAuthenticated.value) {
    await contextStore.loadLocations();
  }
});
</script>
