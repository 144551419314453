<template>
  <PopoverComponent @selected="handleSelect" :options="options" align="end">
    <IconComponent name="PhUserCircle" :data-testid="baseTestId + '.user.trigger'" />
  </PopoverComponent>
</template>

<script setup lang="ts">
import { Configuration, FrontendApi } from '@ory/client';
import { IconComponent, PopoverComponent } from '@shipitsmarter/viya-ui-warehouse';
import { onMounted, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

import { ROUTES } from '@/router/helpers/routes';

const router = useRouter();
const baseTestId = 'nav-bar';
const logoutUrl: Ref<string> = ref('');

const ory = new FrontendApi(
  new Configuration({
    basePath: '/auth/ory',
    baseOptions: {
      withCredentials: true,
    },
  })
);

async function setLogoutUrl() {
  if (import.meta.env.PROD) {
    ory.createBrowserLogoutFlow().then(({ data }) => {
      logoutUrl.value =
        data.logout_url + '&return_to=' + encodeURIComponent(window.location.origin);
    });
  }
}

onMounted(async () => {
  await setLogoutUrl();
});

const handleSelect = ({ path }: { path: string }) => {
  router.push(path);
};

const options = [
  {
    text: 'Profile',
    icon: 'PhUserCircle',
    path: ROUTES.USER_PROFILE.path,
  },
  {
    text: 'Logout',
    icon: 'PhSignOut',
    path: logoutUrl,
  },
];
</script>
