//./plugins/posthog.js
import posthog from 'posthog-js';

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_r3WUJLR2b9x1i0Dpkw8cXQx7kURnbj5BJLS23XeBw9g',
      {
        api_host: 'https://eu.i.posthog.com',
        autocapture: import.meta.env.PROD,
        loaded: function (ph) {
          if (import.meta.env.DEV) {
            ph.opt_out_capturing(); // opts a user out of event capture
            ph.set_config({ disable_session_recording: true });
          }
        },
      }
    );
  },
};
