import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const FtpConfiguration: Array<RouteRecordRaw> = [
  {
    path: ROUTES.FTP_LIST.path,
    name: ROUTES.FTP_LIST.name,
    component: () => import('../pages/ftp/FtpList.vue'),
    meta: {
      title: ROUTES.FTP_LIST.title,
    },
  },
  {
    path: ROUTES.FTP_CLIENT_CREATE.path,
    name: ROUTES.FTP_CLIENT_CREATE.name,
    component: () => import('../pages/ftp/CreateSending.vue'),
    meta: {
      title: ROUTES.FTP_CLIENT_CREATE.title,
    },
  },
  {
    path: ROUTES.FTP_SERVER_CREATE.path,
    name: ROUTES.FTP_SERVER_CREATE.name,
    component: () => import('../pages/ftp/CreateReceiving.vue'),
    meta: {
      title: ROUTES.FTP_SERVER_CREATE.title,
    },
  },
  {
    path: ROUTES.FTP_CLIENT_EDIT.path,
    name: ROUTES.FTP_CLIENT_EDIT.name,
    component: () => import('../pages/ftp/CreateSending.vue'),
    meta: {
      title: ROUTES.FTP_CLIENT_EDIT.title,
    },
  },
  {
    path: ROUTES.FTP_SERVER_EDIT.path,
    name: ROUTES.FTP_SERVER_EDIT.name,
    component: () => import('../pages/ftp/CreateReceiving.vue'),
    meta: {
      title: ROUTES.FTP_SERVER_EDIT.title,
    },
  },
  {
    path: ROUTES.FTP_CLIENT_LOG_LIST.path,
    name: ROUTES.FTP_CLIENT_LOG_LIST.name,
    component: () => import('../pages/ftp/ClientLogs.vue'),
    meta: {
      title: ROUTES.FTP_CLIENT_LOG_LIST.title,
    },
  },
  {
    path: ROUTES.FTP_SERVER_LOG_LIST.path,
    name: ROUTES.FTP_SERVER_LOG_LIST.name,
    component: () => import('../pages/ftp/ServerLogs.vue'),
    meta: {
      title: ROUTES.FTP_SERVER_LOG_LIST.title,
    },
  },
  {
    path: ROUTES.FTP_CREDENTIAL_REQUEST.path,
    name: ROUTES.FTP_CREDENTIAL_REQUEST.name,
    component: () => import('../pages/ftp/CredentialsRequest.vue'),
    meta: {
      title: ROUTES.FTP_CREDENTIAL_REQUEST.title,
      allowAnonymous: true,
    },
  },
];

export default FtpConfiguration;
