import { type RouteRecordRaw } from 'vue-router';

const Onboarding: Array<RouteRecordRaw> = [
  {
    path: '/almost-there',
    name: 'AlmostThere',
    component: () => import('../pages/onboarding/CreatingAccount.vue'),
    props: true,
    meta: {
      title: 'Almost there, we are setting everything up for you',
    },
  },
];

export default Onboarding;
