<script setup lang="ts">
import { DropdownMenuTrigger, type DropdownMenuTriggerProps, useForwardProps } from 'radix-vue';

import { cn } from '@/lib/utils';

const props = defineProps<DropdownMenuTriggerProps>();

const forwardedProps = useForwardProps(props);
</script>

<template>
  <DropdownMenuTrigger
    :class="cn('outline-none data-[state=open]:text-teal-600')"
    v-bind="forwardedProps"
  >
    <slot />
  </DropdownMenuTrigger>
</template>
