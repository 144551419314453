import { type ObjectDirective } from 'vue';

interface ExtendedHTMLElement extends HTMLElement {
  clickOutsideEvent: (event: Event & { target: HTMLElement }) => void;
  pressEscapeKey: (event: KeyboardEvent) => void;
}

export const vClickOutside: ObjectDirective<ExtendedHTMLElement> = {
  beforeMount: function (element, binding) {
    element.clickOutsideEvent = function (event) {
      if (!(element === event.target || element.contains(event.target))) {
        binding.value(event);
      }
    };

    element.pressEscapeKey = function (event) {
      if (event.key === 'Escape') {
        binding.value(event);
      }
    };

    document.body.addEventListener('click', element.clickOutsideEvent as EventListener, {
      passive: true,
    });
    window.addEventListener('keyup', element.pressEscapeKey, { passive: true });
  },
  unmounted: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent as EventListener);
    window.removeEventListener('keyup', element.pressEscapeKey as EventListener);
  },
};
