import { cva } from 'class-variance-authority';

export { default as NavigationMenu } from './NavigationMenu.vue';
export { default as NavigationMenuContent } from './NavigationMenuContent.vue';
export { default as NavigationMenuItem } from './NavigationMenuItem.vue';
export { default as NavigationMenuLink } from './NavigationMenuLink.vue';
export { default as NavigationMenuList } from './NavigationMenuList.vue';
export { default as NavigationMenuTrigger } from './NavigationMenuTrigger.vue';

export const navigationMenuTriggerStyle = cva(
  'group inline-flex h-9 w-max items-center rounded bg-white px-4 py-2 text-sm text-muted transition-colors hover:bg-darker focus:bg-darker focus:text-accent focus:outline-none disabled:pointer-events-none disabled:opacity-disabled data-[active]:bg-darker data-[state=open]:bg-darker'
);
