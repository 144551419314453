import { type RouteRecordRaw } from 'vue-router';

import { ROUTES } from './helpers/routes';

const Configurations: Array<RouteRecordRaw> = [
  {
    path: ROUTES.REFERENCE_LIST.path,
    name: ROUTES.REFERENCE_LIST.name,
    component: () => import('../pages/configuration/ReferenceGenerator.vue'),
    meta: {
      title: ROUTES.REFERENCE_LIST.title,
    },
  },
  {
    path: ROUTES.WEBHOOK_LIST.path,
    name: ROUTES.WEBHOOK_LIST.name,
    component: () => import('../pages/configuration/WebhooksPage.vue'),
    meta: {
      title: ROUTES.WEBHOOK_LIST.title,
    },
  },
  {
    path: ROUTES.SHIPMENT_CONFIG.path,
    name: ROUTES.SHIPMENT_CONFIG.name,
    component: () => import('../pages/configuration/CreateShipment.vue'),
    meta: {
      title: ROUTES.SHIPMENT_CONFIG.title,
    },
  },
  {
    path: ROUTES.DEV_MODE.path,
    name: ROUTES.DEV_MODE.name,
    component: () => import('../pages/configuration/ViewDevMode.vue'),
    meta: {
      title: ROUTES.DEV_MODE.title,
    },
  },
];

export default Configurations;
