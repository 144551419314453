import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import { type AccessMeta, checkAccess } from '@/services/decision';
import { useContextStore } from '@/store';
import { useFeatureFlagsStore } from '@/store/feature-flags';
import { usePrinterStore } from '@/store/printers';
import { useUserStore } from '@/store/user';

import Address from './address';
import CarrierConfiguration from './carrier-configuration';
import Configurations from './configurations';
import Consignments from './consignments';
import FtpConfiguration from './ftp';
import GenericRoutes from './generic';
import HandlingUnits from './handling-units';
import InternalRoutes from './internalRoutes';
import Locations from './locations';
import Onboarding from './onboarding';
import Rates from './rates';
import Shipments from './shipments';
import Tokens from './tokens';
import User from './user';

const routes: Array<RouteRecordRaw> = [
  ...Address,
  ...CarrierConfiguration,
  ...Configurations,
  ...Consignments,
  ...FtpConfiguration,
  ...GenericRoutes,
  ...HandlingUnits,
  ...InternalRoutes,
  ...Locations,
  ...Onboarding,
  ...Rates,
  ...Shipments,
  ...Tokens,
  ...User,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  if (to.meta.allowAnonymous) {
    return;
  }

  const featureFlagStore = useFeatureFlagsStore();
  const userStore = useUserStore();
  const printerStore = usePrinterStore();

  await featureFlagStore.initialise();

  if (!userStore.info) {
    const success = await userStore.tryGetUserInfo();
    if (!success) {
      return '/login';
    }
    // skip on localhost
    if (to.meta.access) {
      // TODO: update the access methods to use the contants defined in authorization.ts - 20231127 - Richard
      const access = to.meta.access as AccessMeta;
      const accessCheck = await checkAccess(access);
      if (!accessCheck) {
        return '/permission-denied';
      }
    }
    await userStore.tryGetUserProfile();
    await printerStore.tryGetPrinters();
  }

  const contextStore = useContextStore();
  // check if getLocations has run or not, if it hasn't run, run it.
  if (!contextStore.locationsLoaded) {
    await contextStore.loadLocations();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title ? `Viya - ${to.meta.title}` : 'Viya';
});

export default router;
