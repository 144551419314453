import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

import { ROUTES } from './helpers/routes';

const HandlingUnits: Array<RouteRecordRaw> = [
  {
    path: ROUTES.HANDLING_UNIT_DETAIL.path,
    name: ROUTES.HANDLING_UNIT_DETAIL.name,
    component: () => import('../pages/handling-units/HandlingUnitPage.vue'),
    props: true,
    meta: {
      title: ROUTES.HANDLING_UNIT_DETAIL.title,
      betaMode: true,
      access: {
        url: 'shipments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
];

export default HandlingUnits;
