import { _GettersTree, defineStore } from 'pinia';

export interface State {
  isPlayground: boolean;
}

interface Getters extends _GettersTree<State> {}

interface Actions {
  switch: () => void;
  setPlayground: () => void;
  setProduction: () => void;
}

const defaultState = (): State => {
  return {
    isPlayground: false,
  };
};

export const usePlaygroundStore = defineStore<string, State, Getters, Actions>('playground', {
  state: defaultState,
  actions: {
    switch() {
      this.isPlayground = !this.isPlayground;
    },
    setPlayground() {
      this.isPlayground = true;
    },
    setProduction() {
      this.isPlayground = false;
    },
  },
});
