import { DirectiveBinding } from 'vue';
import { ref } from 'vue';

import useCurrencyFormatter from '@/composables/useCurrencyFormatter.vue';

const formatCurrency = (el: HTMLElement, binding: DirectiveBinding) => {
  const value = ref(binding.value);
  const currencyCode = ref('EUR');
  const locale = ref('en-US');
  const { formattedValue } = useCurrencyFormatter(value, currencyCode, locale);

  el.innerText = formattedValue.value;
};

export default {
  beforeMount: formatCurrency,
  updated: formatCurrency,
};
