<template>
  <div :class="dynamicStyling.container" class="flex justify-center border-t-2 px-5 py-4">
    <div class="flex w-full">
      <div class="flex-shrink-0">
        <IconComponent
          :name="icon ? icon : dynamicStyling.icon"
          :class="dynamicStyling.iconColour"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 grow">
        <p class="mb-0 text-sm">
          <slot />
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';
import { computed } from 'vue';

type Variants = 'danger' | 'warning' | 'info' | 'success' | 'default' | undefined;

const props = defineProps<{
  variant: Variants;
  icon?: string;
}>();

const generateVariant = (variant: Variants) => {
  const container = {
    danger: 'border-danger-emphasis bg-danger-muted',
    warning: 'border-attention-emphasis bg-attention-muted',
    info: 'border-info-emphasis bg-info-muted',
    success: 'border-success-emphasis bg-success-muted',
    default: 'border-gray-500 bg-gray-100',
  };
  return container[variant];
};

const generateIconStyle = (variant: Variants) => {
  const iconColour = {
    danger: 'text-danger',
    warning: 'text-attention',
    info: 'text-info',
    success: 'text-success',
    default: 'text-muted',
  };
  return iconColour[variant];
};

const dynamicStyling = computed(() => {
  if (props.variant) {
    const styling = {
      info: {
        icon: 'PhInfoCircle',
        container: generateVariant('info'),
        iconColour: generateIconStyle('info'),
      },
      success: {
        icon: 'PhCheckCircle',
        container: generateVariant('success'),
        iconColour: generateIconStyle('success'),
      },
      danger: {
        icon: 'PhXCircle',
        container: generateVariant('danger'),
        iconColour: generateIconStyle('danger'),
      },
      warning: {
        icon: 'PhWarningCircle',
        container: generateVariant('warning'),
        iconColour: generateIconStyle('warning'),
      },
    };
    return styling[props.variant];
  }
  return {
    icon: 'PhInfoCircle',
    iconColour: generateIconStyle('default'),
    container: generateVariant('default'),
  };
});
</script>
